<template>
    <div class="release-video">
        <head-path :list="['直播预热', '视频管理', '发布视频']"></head-path>
        <div class="header">发布视频</div>
        <div class="main">
            <el-scrollbar style="height: 100%;">
                <div class="content">
                    <div class="video-content label">
                        <div class="title">视频内容</div>
                        <div class="icon">*</div>
                    </div>
                    <video-upload v-show="!fileName" ref="upload" @upload="startUpload" @finished="setSrc"></video-upload>
                    <upload-form v-if="fileName" ref="uploadForm" :file-name="fileName" :video-src="videoSrc" @replace="openFile"></upload-form>
                </div>
            </el-scrollbar>
        </div>
        <div class="operation" v-if="fileName">
            <el-button class="cancel" @click="back">取消</el-button>
            <el-button type="primary" @click="release">发布视频</el-button>
        </div>
    </div>
</template>

<script>
import HeadPath from "../../../components/common/HeadPath.vue"

import VideoUpload from "../../../components/student/liveWarmup/VideoUpload.vue"
import UploadForm from "../../../components/student/liveWarmup/UploadForm.vue"
export default {
    name: 'ReleaseVideo',
    components: {
        HeadPath,
        VideoUpload,
        UploadForm
    },
    props: {},
    data() {
        return {
            fileName: "",
            videoSrc: "",
            index: void 0
        }
    },
    computed: {},
    methods: {
        startUpload(fileName) {
            this.fileName = "";
            this.$nextTick(() => this.fileName = fileName);
        },
        setSrc(src) {
            this.videoSrc = src;
        },
        openFile() {
            this.$refs.upload && this.$refs.upload.openFile();
        },
        setDetail(target) {
            this.fileName = target.fileName;
            this.$nextTick(() => {
                this.$refs.uploadForm.$refs.textarea.innerHTML = target.nickname.replaceAll("[未命名标题]", "");
            })
        },
        release() {
            if(!this.fileName) {
                this.$message.error("请上传视频");
                return;
            }else if(!this.$refs.uploadForm.finish) {
                this.$message.error("请等待上传完成");
                return;
            }
            const container = document.createElement("div");
            container.innerHTML = this.$refs.uploadForm.$refs.textarea.innerHTML;
            const topicList = container.querySelectorAll(".topic");
            Array.prototype.forEach.call(topicList, (item, index) => container.removeChild(item));
            const item = {
                nickname: container.innerText ? this.$refs.uploadForm.$refs.textarea.innerHTML : `${this.$refs.uploadForm.$refs.textarea.innerHTML}[未命名标题]`,
                status: "已发布",
                time: new Date().toLocaleDateString().replaceAll("/", "-"),
                //-----------
                fileName: this.fileName
            }
            const list = localStorage.getItem("warmupList") ? JSON.parse(localStorage.getItem("warmupList")) : [];
            if(this.index) {
                list.splice(this.index, 1, item);
            }else {
                list.push(item);
            }
            localStorage.setItem("warmupList", JSON.stringify(list));
            this.$router.replace("/liveWarmup/index");
        },
        back() {
            this.$router.replace("/liveWarmup/index");
        }
    },
    mounted() {
        const targetWarmup = localStorage.getItem("targetWarmup");
        if(this.$route.query.id && targetWarmup) {
            this.index = this.$route.query.id;
            this.setDetail(JSON.parse(targetWarmup));
        }
    }
}
</script>

<style scoped lang="scss">
.release-video {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
    color: #fff;
    .header {
        margin: 0 0 30px;
        font-size: 16px;
    }
    .main {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .content {
            display: flex;
            flex-direction: column;
            height: 100%;
            /deep/ .label {
                margin: 20px 0;
            }
            .video-content {
                display: flex;
                margin-bottom: 20px;
                margin-top: 0;
                .icon {
                    margin-left: 5px;
                    color: #FF3E6C;
                }
            }
        }
        
    }
    .operation {
        text-align: center;
        .cancel {
            margin-right: 10px;
            color: #fff;
            background-color: #0C0E3F;
            border-color: #262963;
        }
    }
}
/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
