<template>
    <div class="video-upload">
        <input class="file-input" ref="fileInput" type="file" accept="video/*" @change="fileChange">
        <div class="upload" @click="openFile">
            <i class="icon el-icon-upload"></i>
            <div class="label">点击上传</div>
            <div class="hint">为了更好的观看体验，超过40秒的视频建议上传横版视频</div>
        </div>
        <div class="hint">
            <div class="left">注意: </div>
            <div class="right-info">
                <div>1.支持常用视频格式，推荐使用mp4、webm；</div>
                <div>2.视频文件大小不超过1G，时长在30分钟以内；</div>
                <div>3.分辨率为720p（1280x720）及以上。</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoUpload',
    components: {},
    props: {},
    data() {
        return {
            // value: ""
        }
    },
    computed: {},
    methods: {
        openFile() {
            this.$refs.fileInput && this.$refs.fileInput.click();
        },
        fileChange(e) {
            const file = e.target.files[0];
            if(!file) return;
            const _this = this;
            const fileName = e.target.value.substring(e.target.value.lastIndexOf("\\") + 1);
            this.$emit("upload", fileName);
            const video = new Blob([file], { type: 'video/mp4' });
            const reader = new FileReader();
            reader.addEventListener("load", ev => {
                _this.$emit("finished", ev.target.result);
            });
            reader.readAsDataURL(video);
        }
    }
}
</script>

<style scoped lang="scss">
.video-upload {
    box-sizing: border-box;
    flex: 1;
    .file-input {
        display: none;
    }
    .upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 660px;
        height: 360px;
        margin-bottom: 20px;
        background-color: #0c0e3f;
        border-radius: 4px;
        cursor: pointer;
        .icon {
            font-size: 64px;
        }
        .label {
            margin: 10px 0 15px;
            font-size: 18px;
        }
    }
    .hint {
        display: flex;
        color: #5F5E80;
    }
}
</style>
