<template>
    <div class="upload-form">
        <div class="form-item video-content-form">
            <div class="video-info">
                <div class="file-name">{{ fileName }}</div>
                <div class="info">
                    <div class="progress-ratio">{{ progress }}%</div>
                    <div class="replace purple" @click="replace">更换视频</div>
                </div>
            </div>
            <div class="progress">
                <el-progress v-if="showProgress" :percentage="progress" :show-text="false"></el-progress>
                <el-progress v-else-if="finish" :percentage="100" :show-text="false"></el-progress>
            </div>
        </div>
        <div class="label">设置封面</div>
        <div class="form-item set-cover">
            <div class="cover">
                <img v-if="imgSrc" class="video" :src="imgSrc" alt="">
                <video v-else-if="videoSrc && finish" class="video"  :src="videoSrc" alt=""></video>
                <div v-else class="default-img">
                    <i class="icon el-icon-picture"></i>
                </div>
            </div>
            <div class="hint">系统默认选中第一帧为视频封面，支持上传封面</div>
            <div class="purple"  @click="openFile">上传封面</div>
        </div>
        <div class="hint">提示：推荐选择与视频尺寸相符的图片，优质的封面会极大增加同城曝光</div>
        <div class="label">视频标题</div>
        <div class="textarea" tabindex="0" ref="textarea" contenteditable @blur="formatHtml(true)"></div>
        <div>
            <span class="purple" @click="addTopic">#添加话题</span>
        </div>
        <input type="file" ref="inputFile" accept="image/*" style="display: none;" @change="uploadImg">
    </div>
</template>

<script>
export default {
    name: 'UploadForm',
    components: {},
    props: {
        fileName: {
            type: String,
            default: ""
        },
        videoSrc: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            progress: 0,
            value: "",
            showProgress: true,
            finish: false,
            imgSrc: ""
        }
    },
    computed: {},
    methods: {
        replace() {
            this.$emit("replace")
        },
        start() {
            this.showProgress = false;
            if(this.fileName && this.$route.query.id) {
                this.finish = true;
                this.progress = 100;
                return;
            }
            this.finish = false;
            this.progress = 0;
            this.$nextTick(() => {
                this.showProgress = true;
                this.$nextTick(() => {
                    setTimeout(() => this.progress = 30, 500);
                    setTimeout(() => this.progress = 80, 2000);
                    setTimeout(() => {
                        this.progress = this.videoSrc ? 100 : 99;
                        this.finish = true;
                    }, 3000);
                })
            })
        },
        addTopic() {
            const textarea = this.$refs.textarea;
            if(!textarea) return;
            textarea.innerHTML += "#";
            textarea.focus();
            this.cursorMoveEnd(textarea);
        },
        cursorMoveEnd(target) {
            if(window.getSelection) {
                target.focus();
                const range = window.getSelection();
                range.selectAllChildren(target);
                range.collapseToEnd();
            }else if(document.selection) {
                const range = document.selection.createRange();
                range.moveToElementText(target);
                range.collapse(false);
                range.select();
            }
        },
        openFile() {
            this.$refs.inputFile && this.$refs.inputFile.click();
        },
        uploadImg(e) {
            const file = e.target.files[0];
            if(!file) return;
            const fileName = e.target.value.substring(e.target.value.lastIndexOf("\\") + 1);
            const reader = new FileReader();
            reader.addEventListener("load", ev => {
                this.imgSrc = ev.target.result;
            })
            reader.readAsDataURL(file);
        },
        setTextareaKeyEvent() {
            const textarea = this.$refs.textarea;
            if(!textarea) return;
            const keys = ["Enter", "Space", "NumpadEnter"];
            textarea.addEventListener("keydown", e => {
                keys.includes(e.code) && e.preventDefault();
            })
            textarea.addEventListener("keyup", this.formatHtml)
        },
        formatHtml(e) {
            const textarea = this.$refs.textarea;
            if(!textarea) return;
            const keys = ["Enter", "Space", "NumpadEnter"];
            const selection = window.getSelection();
            let temp = selection.anchorNode;
            if(temp.nodeType == 1 || !temp.textContent.includes("#")) return;
            let cursorOffset = selection.anchorOffset;
            while(temp = temp.previousSibling) {
                if(temp.nodeType == 1) {
                    cursorOffset += temp.outerHTML.length * 1;
                }else if(temp.nodeType == 3) {
                    cursorOffset += temp.length * 1;
                }
            }
            let beforeContent = textarea.innerHTML.substring(0, cursorOffset);
            const afterContent = textarea.innerHTML.substring(cursorOffset);
            const charOffset = beforeContent.lastIndexOf("#");
            if((keys.includes(e.code) || e === true) && (charOffset != -1 && cursorOffset > charOffset + 1)) {
                const content = beforeContent.substring(charOffset);
                beforeContent = beforeContent.substring(0, charOffset);
                textarea.innerHTML = `${beforeContent}<span class="topic" contenteditable="false">&nbsp;${content}&nbsp;</span>${afterContent}`;
                e === true || this.cursorMoveEnd(textarea);
            }
        }
    },
    mounted() {
        this.start();
        this.setTextareaKeyEvent();
    },
}
</script>

<style scoped lang="scss">
.purple {
    color: #4B45FF;
    cursor: pointer;
}
.upload-form {
    box-sizing: border-box;
    height: 100%;
    .form-item {
        box-sizing: border-box;
        display: flex;
        width: 660px;
        height: 96px;
        background-color: #0C0E3F;
        border-radius: 4px;
    }
    .hint {
        margin-top: 10px;
        color: #5F5E80;
    }
    .video-content-form {
        flex-direction: column;
        justify-content: center;
        padding: 0 40px;
        .video-info {
            display: flex;
            justify-content: space-between;
            .info {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .replace {
                    margin-left: 17px;
                }
            }
        }
    }
    .set-cover {
        padding: 0 50px 0 20px;
        display: flex;
        align-items: center;
        .cover {
            width: 60px;
            height: 60px;
            border-radius: 4px;
            overflow: hidden;
            .video {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .default-img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: #1D2067;
                .icon {
                    font-size: 37px;
                    color: #5559B1;
                }
            }
        }
        .hint {
            box-sizing: border-box;
            flex: 1;
            margin: 0 15px;
            color: #fff;
            opacity: .5;
            overflow: hidden;
        }
    }
    .textarea {
        box-sizing: border-box;
        width: 660px;
        height: 200px;
        padding: 10px;
        margin-bottom: 10px;
        background-color: #0c0e3f;
        outline: none;
        overflow: hidden;
        word-break: break-all;
        /deep/ .el-textarea__inner {
            height: 100%;
            color: #fff;
            background-color: #0c0e3f;
            border: none;
        }
    }
}

</style>
<style>
.topic {
    color: #4B45FF;
}
</style>
